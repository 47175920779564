import React, { useEffect, useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  Nodata,
  NodataTitle,
  NodataDesc,
  NodataMedia,
  NodataGrid,
  NodataItem,
} from "../components/Nodata";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Section,
  Container,
  SectionPageTitle,
  DropSearch,
  DropSearchBody,
  DropSearchItem,
} from "../components/Section";
import { SearchIcon, SearchCloseIcon } from "../components/Icons";
import { useSearch } from "../useSearch";
import { navigate } from "gatsby";
import SearchProducts from "../components/SearchProducts";

const SearchForm = styled.div`
  background-color: #eef0fd;
  border: 1px solid #babfdf;
  border-radius: 6px;
  width: 100%;
  max-width: 460px;
  margin: 0 auto 30px auto;
  position: relative;
  @media (min-width: 1200px) {
    margin: 0 auto 60px auto;
  }
  @media (min-width: 1600px) {
    margin: 0 auto 80px auto;
  }
  .input-icon {
    > .form-control {
      @media (min-width: 1200px) {
        padding: 15px;
      }
      &::placeholder {
        color: #363636;
      }
      &:focus,
      &:active {
        &::placeholder {
          color: #363636;
        }
      }
    }
    .icon {
      width: 45px;
      > svg {
        fill: #363636;
        width: 20px;
      }
      &:hover {
        > svg {
          fill: #e9242a;
        }
      }
    }
    &.right {
      > .form-control {
        padding-right: 45px;
        padding-left: 15px;
      }
    }
  }
`;

const SearchPage = ({ location }) => {
  const [search, setSearch] = useState(location.state?.searchKey || "");
  const [showDropdown, setShowDropdown] = useState(false);
  const targetRef = useRef(null);
  const searchInputRef = useRef(null);
  const { results } = useSearch(search, "", true);

  const handleClickOutside = (event) => {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      if (event.target.id !== searchInputRef.current.id) {
        setShowDropdown(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Layout location={location}>
      <Seo title="Search Result" description="Search Result" />
      <Section
        ept="180px"
        epb="60px"
        xpt="140px"
        xpb="40px"
        pt="130px"
        pb="30px"
        bgColor="#fff"
        className="section-search"
      >
        <Container maxWidth="1600px" id="search-content">
          <SectionPageTitle textAlign="center" className="h2">
            Search Results
          </SectionPageTitle>
          <SearchForm className="search-form">
            <div className="input-icon right">
              <input
                ref={searchInputRef}
                className="form-control"
                type="text"
                placeholder="Search By Product..."
                id="search"
                name="search"
                value={search}
                onFocus={() => setShowDropdown(true)}
                autoComplete="off"
                onChange={(e) => {
                  setSearch(e.target.value);
                  setShowDropdown(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setShowDropdown(false);
                  }
                }}
              />
              <span className="icon">
                <SearchIcon />
              </span>
            </div>
            {showDropdown && (
              <DropSearch ref={targetRef} className="drop-search">
                <DropSearchBody>
                  {!!results &&
                    results.map((item, i) => (
                      <DropSearchItem
                        key={i}
                        onClick={() => navigate(item.url)}
                      >
                        {item.name}
                      </DropSearchItem>
                    ))}
                </DropSearchBody>
              </DropSearch>
            )}
          </SearchForm>
          <SearchProducts products={results} />
        </Container>
      </Section>
    </Layout>
  );
};

export default SearchPage;
